<template>
  <div class="p-home">
 

 
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

// import Input from "@/components/elements/Input.vue";

export default {
  components: {
  },
  data() {
    return {
      details: null,
      editDetails: null,
      userToDelete: null,
      dataToEditSave: {},
    };
  },
  computed: {
    ...mapGetters({
      eventUsers: "EventUsers/getEventUsers",
      registrationInfo: "EventUsers/getRegistrationInfo",
    }),
  },
  methods: {
    ...mapActions({
      fetchEventUsers: "EventUsers/fetchEventUsers",
      fetchRegistrationInfo: "EventUsers/fetchRegistrationInfo",
      deleteEventUsers: "EventUsers/deleteEventUsers",
      edit: "EventUsers/saveEdit",
      saveBookUser: "EventUsers/saveBookUser",
    }),
    getDetails(item) {
      this.details = item;
      this.details.guardian_on_place = this.eventUsers.data.find(
        (e) => e.id == item.guardian_id
      );
    },
    fieldToEditSave(field, value) {
      this.dataToEditSave[field] = value;
    },
    async editSave() {
      this.dataToEditSave.id = this.editDetails.id;
      const response = await this.edit(this.dataToEditSave);
      if (response) {
        this.editDetails = null;
        this.dataToEditSave = {}
      }
    },
  },
  mounted() {
    this.fetchEventUsers();
    this.fetchRegistrationInfo();
  },
};
</script>
<style lang="scss" scoped>
.userDetails {
  &__item {
    margin-bottom: 1rem;
  }
  &__title {
    text-transform: uppercase;
    border-bottom: 1px solid $green;
    margin: 1.5rem -1rem;
    padding: 0 1rem;
    padding-bottom: 0.5rem;
    margin-top: 2.5rem;
    &--first {
      margin-top: 0;
    }
  }
}
</style>
